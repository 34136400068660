<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit {{title}}</b>
      <b v-else>Create {{title}}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{ errors.first('Name') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Description"
          name="Description"
          v-model="data.description"
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Credit Limit"
          name="CreditLimit"
          v-model="data.credit_limit"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('CreditLimit')"
        >{{ errors.first('CreditLimit') }}</span>
      </div>
    </div> -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    },
    url: {
      type: String
    },
    title: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          name: "",
          credit_limit: 99999999,
          description: null
        }
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (this.data.credit_limit) {
          let amount = parseInt(
            this.data.credit_limit.toString().replace(/\./g, "")
          );
          if (amount < 1) {
            this.errors.add({
              field: "CreditLimit",
              msg: "The CreditLimit field is must greater than 0"
            });
            result = false;
          }
        } else {
          this.errors.add({
            field: "CreditLimit",
            msg: "The CreditLimit field is required"
          });
          result = false;
        }
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        name: this.data.name,
        description: this.data.description,
        credit_limit: parseInt(
          this.data.credit_limit.toString().replace(/\./g, "")
        )
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;
            this.data.name = resp.data.name;
            this.data.credit_limit = resp.data.credit_limit;
            this.data.description = resp.data.description;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    }
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "data.credit_limit": function(val) {
      val = val.toString().replace(/\./g, "");
      if (isNaN(parseInt(val))) {
        this.errors.add({
          field: "CreditLimit",
          msg: "The CreditLimit field is number required"
        });
      } else {
        this.errors.clear();
      }
      this.data.credit_limit = val
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
};
</script>